import { Provider } from "react-redux";
import { Playground, store } from "graphql-playground-react";
import { useAuth0 } from "../react-auth0-wrapper";
import React, { useState } from "react";
import config from "../configuration";
import { Loading } from "./Loading";
import { createHttpLink } from "apollo-link-http";
import UserMenu from "./UserMenu";

export const PlaygroundPage = () => {
  const [ready, setReady] = useState(false);
  const [token, setToken] = useState("");
  const { getTokenSilently, loginWithRedirect } = useAuth0();

  getTokenSilently()
    .then(token => {
      setToken(token!);
      setReady(true);
    })
    .catch(error => {
      if (error.error === "login_required") {
        loginWithRedirect({
          appState: { targetUrl: "/" },
        });
      }
    });

  const createApolloLink = ({ endpoint }: { endpoint: string }) => {
    return {
      link: createHttpLink({
        uri: endpoint,
        headers: { Authorization: `Bearer ${token}` },
      }),
    };
  };

  if (!ready) {
    return <Loading />;
  }

  return (
    <>
      <UserMenu />
      <Provider
        // @ts-ignore
        store={store}
      >
        <Playground
          endpoint={config.BUILDING_MODEL_API_URL}
          createApolloLink={createApolloLink}
        />
      </Provider>
    </>
  );
};
