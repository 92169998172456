import React from "react";

export const Loading = () => (<div id="console-loading-wrapper">
  <svg className="logo" viewBox="0 0 128 128" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>GraphQL Playground Logo</title>
    <defs>
      <linearGradient id="linearGradient-1" x1="4.86%" x2="96.21%" y1="0%" y2="99.66%">
        <stop stopColor="#E00082" stopOpacity=".8" offset="0%" />
        <stop stopColor="#E00082" offset="100%" />
      </linearGradient>
    </defs>
    <g>
      <rect id="Gradient" width="127.96" height="127.96" y={1} fill="url(#linearGradient-1)" rx={4} />
      <path id="Border" fill="#E00082" fillRule="nonzero" d="M4.7 2.84c-1.58 0-2.86 1.28-2.86 2.85v116.57c0 1.57 1.28 2.84 2.85 2.84h116.57c1.57 0 2.84-1.26 2.84-2.83V5.67c0-1.55-1.26-2.83-2.83-2.83H4.67zM4.7 0h116.58c3.14 0 5.68 2.55 5.68 5.7v116.58c0 3.14-2.54 5.68-5.68 5.68H4.68c-3.13 0-5.68-2.54-5.68-5.68V5.68C-1 2.56 1.55 0 4.7 0z" />
      <path className="bglIGM" x={64} y={28} fill="#fff" d="M64 36c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8" style={{transform: 'translate(100px, 100px)'}} />
      <path className="ksxRII" x="95.98500061035156" y="46.510000228881836" fill="#fff" d="M89.04 50.52c-2.2-3.84-.9-8.73 2.94-10.96 3.83-2.2 8.72-.9 10.95 2.94 2.2 3.84.9 8.73-2.94 10.96-3.85 2.2-8.76.9-10.97-2.94" style={{transform: 'translate(100px, 100px)'}} />
      <path className="cWrBmb" x="95.97162628173828" y="83.4900016784668" fill="#fff" d="M102.9 87.5c-2.2 3.84-7.1 5.15-10.94 2.94-3.84-2.2-5.14-7.12-2.94-10.96 2.2-3.84 7.12-5.15 10.95-2.94 3.86 2.23 5.16 7.12 2.94 10.96" style={{transform: 'translate(100px, 100px)'}} />
      <path className="Wnusb" x={64} y="101.97999572753906" fill="#fff" d="M64 110c-4.43 0-8-3.6-8-8.02 0-4.44 3.57-8.02 8-8.02s8 3.58 8 8.02c0 4.4-3.57 8.02-8 8.02" style={{transform: 'translate(100px, 100px)'}} />
      <path className="bfPqf" x="32.03982162475586" y="83.4900016784668" fill="#fff" d="M25.1 87.5c-2.2-3.84-.9-8.73 2.93-10.96 3.83-2.2 8.72-.9 10.95 2.94 2.2 3.84.9 8.73-2.94 10.96-3.85 2.2-8.74.9-10.95-2.94" style={{transform: 'translate(100px, 100px)'}} />
      <path className="edRCTN" x="32.033552169799805" y="46.510000228881836" fill="#fff" d="M38.96 50.52c-2.2 3.84-7.12 5.15-10.95 2.94-3.82-2.2-5.12-7.12-2.92-10.96 2.2-3.84 7.12-5.15 10.95-2.94 3.83 2.23 5.14 7.12 2.94 10.96" style={{transform: 'translate(100px, 100px)'}} />
      <path className="iEGVWn" stroke="#fff" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" d="M63.55 27.5l32.9 19-32.9-19z" />
      <path className="bsocdx" stroke="#fff" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" d="M96 46v38-38z" />
      <path className="jAZXmP" stroke="#fff" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" d="M96.45 84.5l-32.9 19 32.9-19z" />
      <path className="hSeArx" stroke="#fff" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" d="M64.45 103.5l-32.9-19 32.9 19z" />
      <path className="bVgqGk" stroke="#fff" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" d="M32 84V46v38z" />
      <path className="hEFqBt" stroke="#fff" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" d="M31.55 46.5l32.9-19-32.9 19z" />
      <path className="dzEKCM" id="Triangle-Bottom" stroke="#fff" strokeWidth={4} d="M30 84h70" strokeLinecap="round" />
      <path className="DYnPx" id="Triangle-Left" stroke="#fff" strokeWidth={4} d="M65 26L30 87" strokeLinecap="round" />
      <path className="hjPEAQ" id="Triangle-Right" stroke="#fff" strokeWidth={4} d="M98 87L63 26" strokeLinecap="round" />
    </g>
  </svg>
  <div className="text">Loading
    <span className="dGfHfc"> GraphQL Console</span>
  </div>
</div>);