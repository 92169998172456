import React from "react";
import { useAuth0 } from "../react-auth0-wrapper";
import config from "../configuration";

const baseTextStyle = {
  fontFamily: "'Open Sans",
  letterSpacing: "0.3px",
};

const UserMenu = () => {
  const { loading, user, logout } = useAuth0();

  if (loading || !user) {
    return null;
  }

  return (
    <div
      style={{
        position: "absolute",
        right: "100px",
        bottom: "8px",
        zIndex: 10,
      }}
    >
      <span
        style={{
          ...baseTextStyle,
          fontWeight: 700,
          color: "#39c4dd",
          paddingRight: "3px",
        }}
      >
        {user.name}
      </span>
      <span
        style={{
          ...baseTextStyle,
          color: "#ddd",
          paddingRight: "10px",
        }}
      >
        ({user.email})
      </span>
      <button
        style={{
          backgroundColor: "rgba(255,255,255,0)",
          color: "#ddd",
          border: "2px solid #ddd",
          cursor: "pointer",
          borderRadius: "4px",
          fontFamily: "Source Code Pro",
          fontWeight: 600,
          fontSize: "14px",
          textTransform: "uppercase",
        }}
        onClick={() =>
          logout({
            returnTo: config.AUTH0_SETTINGS.logoutUrl,
            client_id: config.AUTH0_SETTINGS.clientID,
          })
        }
      >
        Logout
      </button>
    </div>
  );
};

export default UserMenu;
