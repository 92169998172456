import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import { PlaygroundPage } from "./components/PlaygroundPage";
import history from "./utils/history";
import { useAuth0 } from "./react-auth0-wrapper";
import { Loading } from "./components/Loading";
import LoginRedirect from "./components/LoginRedirect";

const App: React.FC = () => {
  const { loading } = useAuth0();

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          <Route path="/" exact component={PlaygroundPage} />
          <Route path="/login" exact component={LoginRedirect} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
