declare var config: {
  BUILDING_MODEL_API_URL: string;
  BUILDING_MODEL_API_KEY: string;
  AUTH0_CLAIMS_NAMESPACE: string;
  AUTH0_SETTINGS: {
    domain: string;
    clientID: string;
    redirectUri: string;
    logoutUrl: string;
    audience: string;
  };
  AUTH0_REALM: string;
};

config = {
  ...config,
  BUILDING_MODEL_API_URL: process.env.REACT_APP_BUILDING_MODEL_API_URL
    ? process.env.REACT_APP_BUILDING_MODEL_API_URL
    : config.BUILDING_MODEL_API_URL,
  BUILDING_MODEL_API_KEY: process.env.REACT_APP_BUILDING_MODEL_API_KEY
    ? process.env.REACT_APP_BUILDING_MODEL_API_KEY
    : config.BUILDING_MODEL_API_KEY,
  AUTH0_SETTINGS: {
    ...config.AUTH0_SETTINGS,
    redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI
      ? process.env.REACT_APP_AUTH_REDIRECT_URI
      : config.AUTH0_SETTINGS
      ? config.AUTH0_SETTINGS.redirectUri
      : "",
  },
};

// weird typescript default export workaround
let defaultConfig = config;
export default defaultConfig;
