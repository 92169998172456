import { useEffect } from "react";
import { useAuth0 } from "../react-auth0-wrapper";
import history from "../utils/history";

const LoginRedirect = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    const fn = async () => {
      if (!isAuthenticated) {
        await loginWithRedirect({
          appState: { targetUrl: "/" },
        });
      }
      else {
        history.replace("/");
      }
    };
    fn();
  }, [isAuthenticated, loginWithRedirect]);

  return null;
};

export default LoginRedirect;
